import { PencilIcon, PlusInCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { FunctionComponent, useEffect } from 'react';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import { ContactInfoItemRenderer } from './components/ContactInfoItemRenderer';
import { ContactInfoModal } from './components/ContactInfoModal';
import { DepartmentHoursItemRenderer } from './components/DepartmentHoursItemRenderer';
import { DepartmentHoursModal } from './components/DepartmentHoursModal';
import { DepartmentItemRenderer } from './components/DepartmentItemRenderer';
import { DepartmentModal } from './components/DepartmentModal';
import { usePharmacySettingsState } from './pharmacy-settings-store';

export const PharmacySettings: FunctionComponent = () => {
  const {
    pharmacy,
    departments,
    departmentsHours,
    setShowModalContactInfo,
    setShowModalDepartment,
    setShowModalDepartmentHours,
    setPharmacyId,
    getDepartments,
    getDepartmentsHours,
  } = usePharmacySettingsState();
  const { pharmacyId } = useAppStateStore();
  useEffect(() => {
    // TODO Currently, this value is hardcoded, but we intend to retrieve it from the login process.
    setPharmacyId(pharmacyId)
      .then(() => getDepartments())
      .then(() => getDepartmentsHours())
      .catch((error: Error) => logError(error));
  }, []);

  return pharmacy ? (
    <SettingsPageLayout title={getText('pharmacy')}>
      <SettingsSection
        title={getText('pharmacy-contact-info')}
        contentHeader={{
          title: getText('pharmacy-general-details'),
          button: {
            hierarchy: 'tertiary',
            logger: { id: 'settings-header-button' },
            buttonTitle: getText('edit'),
            icon: PencilIcon,
            onPress: () => setShowModalContactInfo(true),
          },
        }}
        contentData={[pharmacy]}
        contentItem={ContactInfoItemRenderer}
        customTypeModal={ContactInfoModal}
      />
      <SettingsSection
        title={getText('pharmacy-departments')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            hierarchy: 'tertiary',
            logger: { id: 'settings-header-button' },
            buttonTitle: getText('new'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalDepartment(true),
          },
        }}
        contentData={departments}
        contentItem={DepartmentItemRenderer}
        customTypeModal={DepartmentModal}
      />
      <SettingsSection
        title={getText('pharmacy-departments-hours')}
        subtitle={getText('pharmacy-departments-hours-subtitle')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            hierarchy: 'tertiary',
            logger: { id: 'settings-header-button' },
            buttonTitle: getText('new'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalDepartmentHours(true),
          },
        }}
        contentData={departmentsHours}
        contentItem={DepartmentHoursItemRenderer}
        customTypeModal={DepartmentHoursModal}
      />
    </SettingsPageLayout>
  ) : (
    <>Pharmacy N/A</>
  );
};
