import {
  CreateDepartmentDto,
  CreateNormalTimeRangeDto,
  DepartmentDto,
  NormalTimeRangeDto,
  PharmacyDto,
  UpdateDepartmentDto,
  UpdatePharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { logError } from 'assets/logging/logger';
import create from 'zustand';
import PharmacyService from '../../../api/PharmacyService';
import { useAppStateStore } from '../../../store/app-store';
import { AsyncStatus } from '../../../store/types';

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export enum DaysOfWeekEnum {
  Sunday = 1,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export interface DepartmentHours {
  departmentId: string;
  departmentName: string;
  departmentHours: NormalTimeRangeDto[];
}

export interface PharmacySettingsState {
  showModalContactInfo: boolean;
  showModalDepartmentHours: boolean;
  showModalDepartment: boolean;
  pharmacy?: PharmacyDto;
  status: AsyncStatus;
  editingGeneralInfo: boolean;
  editGeneralInfoId?: string;
  pharmacyId?: string;
  country: CountryCode;
  departmentsHours: DepartmentHours[];
  editingDepartmentHours?: DepartmentHours;
  setShowModalContactInfo: (showModalContactInfo: boolean) => void;
  setShowModalDepartmentHours: (
    showModalDepartmentHours: boolean,
    editingDepartmentId?: string,
  ) => void;
  departments: DepartmentDto[];
  editingDepartmentId?: string;
  editingDepartment?: DepartmentDto;
  setShowModalDepartment: (
    showModalDepartment: boolean,
    editingDepartmentId?: string,
  ) => void;
  setPharmacy: (pharmacy: UpdatePharmacyDto) => Promise<void>;
  updateDepartment: (
    editingDepartment: UpdateDepartmentDto,
    departmentId?: string,
  ) => Promise<void>;
  createDepartment: (department: CreateDepartmentDto) => Promise<void>;
  removeDepartment: (departmentId?: string) => Promise<void>;
  setCountry: (country: CountryCode) => void;
  setPharmacyId: (pharmacyId: string) => Promise<void>;
  getPharmacy: () => Promise<void>;
  createDepartmentHours: (
    timeRange: CreateNormalTimeRangeDto[],
    departmentName?: string,
  ) => Promise<void>;
  getDepartmentsHours: () => Promise<void>;
  getEditingDepartmentHours: () => void;
  setDepartmentsHours: (departmentsHours: DepartmentHours[]) => void;
  getDepartments: () => Promise<void>;
  getEditingDepartment: () => void;
  setEditingDepartmentId: (editingDepartmentId?: string) => void;
}

export const usePharmacySettingsState = create<PharmacySettingsState>(
  (set, get) => ({
    showModalContactInfo: false,
    showModalDepartmentHours: false,
    showModalDepartment: false,
    status: 'idle',
    editingGeneralInfo: false,
    pharmacyId: useAppStateStore.getState().pharmacyId,
    country: CountryCode.US,
    departments: [],
    departmentsHours: [],
    setShowModalContactInfo: (showModalContactInfo: boolean) =>
      set({ showModalContactInfo }),
    setShowModalDepartmentHours: (showModalDepartmentHours: boolean) => {
      set({ showModalDepartmentHours });
    },
    setEditingDepartmentId: (editingDepartmentId?: string) => {
      set({ editingDepartmentId });
    },
    setShowModalDepartment: (
      showModalDepartment: boolean,
      editingDepartmentId?: string,
    ) => {
      set({ showModalDepartment, editingDepartmentId });
      get().getEditingDepartment();
    },
    setPharmacy: async (pharmacy: UpdatePharmacyDto) => {
      const { pharmacyId } = get();
      if (pharmacyId) {
        await PharmacyService.updatePharmacyRecord(pharmacyId, pharmacy);
        set((prevState) => ({ ...prevState, ...pharmacy }));
      }
    },
    updateDepartment: async (
      editingDepartment: UpdateDepartmentDto,
      departmentId?: string,
    ) => {
      const { pharmacyId, editingDepartmentId, getDepartments } = get();
      departmentId = departmentId || editingDepartmentId;
      if (pharmacyId && departmentId) {
        await PharmacyService.updatePharmacyDepartment(
          pharmacyId,
          departmentId,
          editingDepartment,
        );
        set((prevState) => ({ ...prevState, ...editingDepartment }));
        await getDepartments();
      }
    },
    createDepartment: async (department: CreateDepartmentDto) => {
      const { pharmacyId, getDepartments } = get();
      if (pharmacyId) {
        await PharmacyService.createPharmacyDepartment(
          pharmacyId,
          department,
        ).then(async () => await getDepartments());
      }
    },
    removeDepartment: async (departmentId?: string) => {
      const { pharmacyId, editingDepartmentId, getDepartments } = get();
      departmentId = departmentId || editingDepartmentId;
      if (pharmacyId && departmentId) {
        await PharmacyService.removePharmacyDepartment(
          pharmacyId,
          departmentId,
        ).then(async () => {
          await getDepartments();
          const editingDepartmentId = undefined;
          const editingDepartment = undefined;
          set({ editingDepartmentId, editingDepartment });
        });
      }
    },
    setCountry: (country: CountryCode) => set({ country }),
    setPharmacyId: async (pharmacyId: string) => {
      set({ pharmacyId });
      await get().getPharmacy();
    },
    getPharmacy: async () => {
      const pharmacyId = get().pharmacyId;
      if (!pharmacyId) {
        set({ pharmacy: undefined });
        return;
      }
      await PharmacyService.pharmacyGetById(pharmacyId).then((pharmacy) =>
        set(() => ({ pharmacy })),
      );
    },
    createDepartmentHours: async (
      timeRange: CreateNormalTimeRangeDto[],
      departmentName?: string,
    ) => {
      const { editingDepartmentId, pharmacyId } = get();
      if (editingDepartmentId) {
        await PharmacyService.createDepartmentHours(
          editingDepartmentId,
          timeRange,
        );
      } else if (departmentName && pharmacyId) {
        await PharmacyService.createPharmacyDepartment(pharmacyId, {
          name: departmentName,
        })
          .then((department) =>
            PharmacyService.createDepartmentHours(department.id, timeRange),
          )
          .catch((error: Error) => logError(error));
      }
    },
    getDepartmentsHours: async () => {
      const { departments, setDepartmentsHours } = get();
      const newDepartmentsHours: DepartmentHours[] = await Promise.all(
        departments.map(async (department) => {
          return {
            departmentId: department.id,
            departmentName: department.name,
            departmentHours: await PharmacyService.getHoursByDepartment(
              department.id,
            ),
          };
        }),
      );
      setDepartmentsHours(newDepartmentsHours);
    },
    getEditingDepartmentHours: () => {
      const { departmentsHours, editingDepartmentId } = get();
      const editingDepartmentHours = editingDepartmentId
        ? departmentsHours.find(
            (department) => department.departmentId === editingDepartmentId,
          )
        : undefined;
      set({ editingDepartmentHours });
    },
    setDepartmentsHours: (departmentsHours: DepartmentHours[]) =>
      set({ departmentsHours }),
    getDepartments: async () => {
      const pharmacyId = get().pharmacyId;
      if (!pharmacyId) {
        set({ departments: [] });
        return;
      }
      await PharmacyService.departmentGetByPharmacy(pharmacyId).then(
        (departments) => set(() => ({ departments })),
      );
    },
    getEditingDepartment: () => {
      const { editingDepartmentId, departments } = get();
      const editingDepartment = editingDepartmentId
        ? departments.find((dep) => dep.id == editingDepartmentId)
        : undefined;
      set({ editingDepartment });
    },
  }),
);
