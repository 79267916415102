import {
  PharmacyDto,
  PharmacyLocationDto,
  DefaultApi as PharmacyServiceClient,
  UpdatePharmacyDto,
  DepartmentDto,
  CreateDepartmentDto,
  UpdateDepartmentDto,
  PaginatedLocationResponseDto,
  NormalTimeRangeDto,
  CreateNormalTimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { PHARMACY_API_BASE_URL } from '../common/constants';

export interface IPharmacyService {
  pharmacyLocationFindOne(locationId: string): Promise<PharmacyLocationDto>;
  pharmacyLocationFindAll(): Promise<PaginatedLocationResponseDto>;
  pharmacyGetById(corporationId: string): Promise<PharmacyDto>;
  updatePharmacyRecord(
    corporationId: string,
    updatePharmacyDto: UpdatePharmacyDto,
  ): Promise<PharmacyDto>;
  getHoursByDepartment(departmentId: string): Promise<NormalTimeRangeDto[]>;
  createDepartmentHours(
    departmentId: string,
    timeRange: CreateNormalTimeRangeDto[],
  ): Promise<NormalTimeRangeDto[]>;
  departmentGetByPharmacy(pharmacyId: string): Promise<DepartmentDto[]>;
  departmentGetById(
    pharmacyId: string,
    departmentId: string,
  ): Promise<DepartmentDto>;
  createPharmacyDepartment(
    pharmacyId: string,
    department: CreateDepartmentDto,
  ): Promise<DepartmentDto>;
  updatePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
    department: UpdateDepartmentDto,
  ): Promise<DepartmentDto>;
  removePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
  ): Promise<void>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async pharmacyLocationFindOne(
    locationId: string,
  ): Promise<PharmacyLocationDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationFindOne(locationId);
    return data;
  }

  async pharmacyLocationFindAll(): Promise<PaginatedLocationResponseDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind();
    return data;
  }

  async pharmacyGetById(corporationId: string): Promise<PharmacyDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyFindOne(corporationId);
    return data;
  }
  async updatePharmacyRecord(
    corporationId: string,
    updatePharmacyDto: UpdatePharmacyDto,
  ): Promise<PharmacyDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyUpdate(
      corporationId,
      updatePharmacyDto,
    );
    return data;
  }

  async getHoursByDepartment(
    departmentId: string,
  ): Promise<NormalTimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.departmentHoursFindAll(departmentId);
    return data;
  }
  async createDepartmentHours(
    departmentId: string,
    timeRange: CreateNormalTimeRangeDto[],
  ): Promise<NormalTimeRangeDto[]> {
    const { data } = await this.pharmacyServiceClient.departmentHoursCreate(
      departmentId,
      timeRange,
    );
    return data;
  }
  async departmentGetByPharmacy(pharmacyId: string): Promise<DepartmentDto[]> {
    const { data } =
      await this.pharmacyServiceClient.departmentFindAll(pharmacyId);
    return data;
  }
  async departmentGetById(
    pharmacyId: string,
    departmentId: string,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentFindOne(
      pharmacyId,
      departmentId,
    );
    return data;
  }
  async createPharmacyDepartment(
    pharmacyId: string,
    department: CreateDepartmentDto,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentCreate(
      pharmacyId,
      department,
    );
    return data;
  }
  async updatePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
    department: UpdateDepartmentDto,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentUpdate(
      pharmacyId,
      departmentId,
      department,
    );
    return data;
  }
  async removePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
  ): Promise<void> {
    const { data } = await this.pharmacyServiceClient.departmentRemove(
      pharmacyId,
      departmentId,
    );
    return data;
  }
}

export default new PharmacyService(
  PHARMACY_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
